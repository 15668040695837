<template>
  <div class="my-4">
    <b-container>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="complianceChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" lg="6" class="mb-4">
          <b-card>
            <div id="comparisonChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
        <b-col md="12" lg="6" class="mb-4">
          <b-card>
            <div id="ownersChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  name: 'AppObligationsDashboard',
  computed: {
    dataFiltered: {
      get () {
        return this.$store.state.appObligationsData
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'App Obligations dash', action: 'open App Obligations dash' })
    this.load()
  },
  data () {
    return {
      loadingNewsRisk: true,
      news: [],
      newsRiskStartDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
      newsRiskEndDate: moment().add(1, 'days').format('YYYY-MM-DD')
    }
  },
  methods: {
    changeDate: function () {
      this.$stat.log({ page: 'MAR dash', action: 'change date', payload: { newsRiskStartDate: this.newsRiskStartDate, newsRiskEndDate: this.newsRiskEndDate } })
      echarts.dispose(document.getElementById('martrendchart'))
      echarts.dispose(document.getElementById('countrieschart'))
      echarts.dispose(document.getElementById('regulatorschart'))
      this.load()
    },
    load: async function () {
      await delay(100)
      let comparison = []
      let compliance = []
      let owners = []
      _.each(_.countBy(this.dataFiltered, x => x.compliance_level), (value, key) => {
        compliance.push({ name: key, value: value, label: { position: 'left' } })
      })
      compliance = _.sortBy(compliance, ['value'])
      _.each(_.countBy(this.dataFiltered, x => x.comparison_to_peers), (value, key) => {
        comparison.push({ name: key, value: value, label: { position: 'left' } })
      })
      compliance = _.sortBy(compliance, ['value'])
      _.each(_.countBy(this.dataFiltered, x => x.rule_owner), (value, key) => {
        owners.push({ name: key, value: value, label: { position: 'left' } })
      })
      owners = _.sortBy(owners, ['value'])
      const complianceOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '30%'
        },
        xAxis: {
          type: 'value',
          show: false,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: compliance
        },
        series: [
          {
            name: 'obligations',
            type: 'bar',
            stack: 'obligations',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: compliance
          }
        ]
      }
      const comparisonOptions = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: 'Countries',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '50%'],
            data: comparison,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      const ownersOptions = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: 'Regulators',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '50%'],
            data: owners,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      echarts.init(document.getElementById('complianceChart'), 'kpmg').setOption(complianceOptions)
      echarts.init(document.getElementById('comparisonChart'), 'kpmg').setOption(comparisonOptions)
      echarts.init(document.getElementById('ownersChart'), 'kpmg').setOption(ownersOptions)
    },
    moment: function (value) {
      return moment(value)
    }
  },
  watch: {
    dataFiltered: function () {
      echarts.dispose(document.getElementById('complianceChart'))
      echarts.dispose(document.getElementById('comparisonChart'))
      echarts.dispose(document.getElementById('ownersChart'))
      this.load()
    }
  }
}
</script>

<style></style>
